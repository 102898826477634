.wrapper {
  background-color: #e6e7e9;
  margin-top: 20px;
  padding: 20px;
  border-radius: 20px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(18rem, 2fr));
  column-gap: 10px;
  row-gap: 40px;
}

.onlyReadInput {
  pointer-events: none;
}

.expandableInput {
  width: 100%;
  min-height: 20rem;
  overflow: visible;
  white-space: initial;
  background-color: red;
}

.title{
  display: flex;
}

.fragment{
  width: 20rem;
  display: inline;
}

.divOverflow{
  margin-top: 1rem;
  margin-bottom: 1rem;
  background-color: transparent;
  overflow-x: visible;
  overflow-wrap: break-word;
  border: 1px solid #D9D9D9;
  text-align: left;
  padding-left: 0.5rem;
  padding-top: 0.3rem;
  padding-bottom: 0.3rem;
}

.warning{
  color: red;
}

.wrapperParagraph{
  width: 90%;
  display: inline-block;
}

.copy{
  width: 10%;
  display: inline-block;
}

.paragraph{
  display: inline;
}

.labelBank {
  margin-right: 5px;
  font-size: 19px;
  color: #142054;
}

.btnConfirm {
  margin-top: 1rem;
  border-radius: 10px;
  margin-bottom: 20px;
}