.container {
  background-color: #e6e7e9;
  padding: 50px;
  border-radius: 20px;
  margin-top: 15px;
  margin-bottom: 30px;
  width: 90vw; 
  text-align: center;
}

.btnConfirm {
  border-radius: 10px;
  margin-bottom: 20px;
}

.containerButton {
  text-align: center;
  margin-top: 23px;
}
