
.container{
    padding-bottom: 50px;
    padding-top: 50px;
}

.title{
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 20px;
    color: rgb(250, 124, 124);
    font-weight: 800;
}

.subTitle{
    padding-left: 20px;
}

.faqContainer{
    margin-top: 30px;
    border-radius: 15px;
    border-radius: 15px;
    box-shadow: 6px 6px 8px 0px rgba(0, 0, 0, 0.637);
}

.whiteSpace{
    height: 68px;
}

.text{
    text-align: justify;
}