.title {
  font-size: 20px;
  color: #142054;
  margin-bottom: 15px;
}

.container {
  margin-top: 50px;
  background-color: #e6e7e9;
  border-radius: 20px;
}

.containerSign {
  margin-top: 30px;
  text-align: center;
}

.containerPdfReader {
  text-align: center;
}

@media screen and (min-width: 600px) {
  .title {
    font-size: 30px;
  }
}

@media screen and (min-width: 1005px) {
  .title {
    font-size: 50px;
  }
}
