.title {
  color: #08c;
}
.inputNumber {
  width: 160px;
}

.inputDate {
  width: 160px;
}

.marginRow {
  margin-top: 20px;
}

.loadingSpiner {
  margin: 20px 0;
  margin-bottom: 20px;
  padding: 30px 50px;
  text-align: center;
  border-radius: 4px;
}

.colorDiv {
  background-color: #c4c4c4;
  padding-left: 2px;
  width: 400px;
  height: 50px;
  align-items: center;
  display: flex;
}

.sizeInputs{
  width: 200px;
}

.btnDownloadCsv{
  width: 200px;
  height: 70px;
  font-size: 20px;
}