.wrapper {
  background-color: #e6e7e9;
  margin-top: 20px;
  padding: 20px;
  border-radius: 20px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(18rem, 2fr));
  column-gap: 10px;
  row-gap: 40px;
}
