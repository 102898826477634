/* SECTION 3 */
.icons img {
  margin-bottom: 40px;
  padding: 21px;
  border-radius: 35px;
}
.svg {
  height: 100px;
  width: 100px;
  background-color: #f44f5e;
  color: white;
  padding: 8px;
  border-radius: 300px;
  margin-right: 20px;
}
.container {
  padding: 100px;
  background-color: #ffe5e7;
}
.columns {
  text-align: center;
}
.texth4 {
  color: #142054;
}
.text {
  color: #142054;
}
/* SECTION 4 */
.boxImg {
  width: 100%;
  height: 600px;
}
.arrow {
  margin-top: 60px;
  margin-left: 240px;
}
@supports (object-fit: cover) {
  .boxImg img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center center;
  }
}
.boxText {
  background-color: #f9f9f9;
}
.texts {
  margin-top: 200px;
  margin-left: 80px;
}
/* SECTION 5 */
.containerSection5 {
  padding: 100px 50px;
}
.divicons {
  margin-left: 20px;
}
.svgS5 {
  height: 80px;
  width: 80px;
  background-color: #f44f5e;
  color: white;
  padding: 5px 15px;
  border-radius: 25px;
}
.titleSection5 {
  font-size: 20px;
  color: #f44f5e;
  font-weight: bold;
}
.textSection5 {
  font-size: 14px;
  color: gray;
}
.row1section5 {
  margin-bottom: 50px;
}
/* SECTION 6 */
.divSection6 {
  padding: 150px;
  background-color: #f9f9f9;
}
.titleSection6 {
  color: #142054;
  text-align: center;
  font-size: 60px;
  margin-bottom: 15px;
}
.subTitleSection6 {
  color: #f44f5e;
  text-align: center;
  font-size: 25px;
}
.diviconGray {
  background-color: rgb(194, 194, 194);
  color: white;
  padding: 2px 22px;
  border-radius: 120px;
  margin-bottom: 40px;
  margin-left: 55px;
  height: 100px;
  width: 100px;
}
.diviconRose {
  background-color: #f44f5e;
  color: white;
  padding: 2px 22px;
  border-radius: 120px;
  margin-bottom: 40px;
  margin-left: 55px;
  height: 100px;
  width: 100px;
}
.h4Section6 {
  color: #f44f5e;
  font-size: 20px;
  font-weight: 300;
  letter-spacing: 2.5px;
  text-align: center;
}
.pSection6 {
  text-align: center;
  color: rgb(105, 105, 105);
}
.divIconSection6 {
  justify-content: space-between;
  margin-top: 80px;
}
.arrowSteps {
  transform: rotateZ(-90deg);
  margin-top: 30px;
}
/* MEDIAS QUERIES */
@media screen and (max-width: 1200px) {
  .divSection6 {
    padding: 150px 20px;
  }
}
@media screen and (max-width: 800px) {
  /* SECTION 4 */
  .boxImg {
    width: 100%;
    height: 433px;
  }
  .arrow {
    margin-left: 360px;
  }
  /* SECTION 5 */
  .containerSection5 {
    padding: 30px;
  }
  .divicons {
    margin-left: 20px;
  }
  .svgS5 {
    height: 40px;
    width: 40px;
    background-color: #f44f5e;
    color: white;
    padding: 5px 11px;
    border-radius: 13px;
  }
  .titleSection5 {
    font-size: 15px;
    color: #f44f5e;
    font-weight: 500;
    margin-left: 10px;
  }
  .textSection5 {
    font-size: 11px;
    color: gray;
    margin-left: 10px;
  }
  .row1section5 {
    margin-bottom: 0px;
  }
  /* SECTION 6 */
  .divSection6 {
    padding: 60px;
  }
  .titleSection6 {
    font-size: 30px;
    margin-bottom: 10px;
  }
  .subTitleSection6 {
    font-size: 15px;
  }
  .displayMdNone {
    display: none;
  }
  .center {
    text-align: center;
  }
  .divIconSection6 {
    margin-top: 40px;
  }
  .divIcons2 {
    margin-top: 10px;
  }
  .diviconRose {
    margin-left: 0px;
  }
  .diviconGray {
    margin-left: 0px;
  }
}
@media screen and (max-width: 500px) {
  /* SECTION 3 */
  .icons i {
    padding: 20px;
    margin-bottom: 30px;
    margin-right: 40px;
    font-size: 20px;
  }
  .container {
    padding: 30px;
    background-color: #ffe5e7;
  }
  .text {
    font-size: 11px;
  }
  .colsm {
    margin-top: 30px;
  }
  .columns {
    text-align: start;
  }
  /* SECTION 4 */
  .boxImg {
    width: 100%;
    height: 220px;
  }
  .texts {
    margin-top: 60px;
    margin-left: 20px;
  }
  .arrow {
    margin-top: 30px;
    margin-left: 180px;
  }
  .colImg{
    width: 100%;
  }
  .boxText{
    width: 100%;
  }
  /* SECTION 5 */
  .containerSection5 {
    padding: 10px 0px;
  }
  /* SECTION 6 */
  .divIconSection6 {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .diviconGray {
    margin-bottom: 10px;
    margin-top: 15px;
  }
  .diviconRose {
    margin-bottom: 10px;
    margin-top: 20px;
  }
}
