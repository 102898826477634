.rowCards {
  margin-bottom: 40px;
}
.card {
  width: 400px;
  box-shadow: 11px 13px 17px 1px rgba(142, 142, 142, 0.6);
  -webkit-box-shadow: 11px 13px 17px 1px rgba(142, 142, 142, 0.6);
  -moz-box-shadow: 11px 13px 17px 1px rgba(142, 142, 142, 0.6);
  margin: 30px;
  border: none;
  border-radius: 20px;
}
.imgCardContainer {
  height: 200px;
  width: 70%;
  border-radius: 20px;
}
.arrowCollapse {
  color: #f44f5e;
}
.aHeaderPanel {
  color: #f44f5e;
  font-size: 15px;
  font-weight: bold;
  text-align: end;
  margin-right: 15px;
}
@supports (object-fit: cover) {
  .imgCard {
    height: 70%;
    width: 50%;
    object-fit: contain;
    margin: 30px;
    object-position: center center;
  }
}
.pClasification {
  margin: 20px 10px;
  color: #f44f5e;
}
.titleCard {
  margin: 10px 10px;
  font-weight: bold;
}
.pDate {
  margin: 5px 0px 0px 10px;
  color: #5e5e5e;
}
.rowDataCard {
  margin: 15px 0px;
}
.aViewMore {
  color: #f44f5e;
  margin-top: 30px;
}
.arrow {
  transform: rotateZ(270deg);
  margin-left: 25px;
  height: 30px;
}
.arrow2 {
  margin-left: 25px;
  height: 30px;
}

@media screen and (max-width: 1050px) {
  .card {
    margin: 20px;
  }
}

@media screen and (max-width: 500px) {
  .imgCard {
    margin-left: 100px;
  }
  .pClasification {
    margin-left: 30px;
    color: #f44f5e;
  }
  .titleCard {
    margin: 0px 30px;
    font-weight: bold;
  }
  .pDate {
    margin: 5px 0px 0px 30px;
    color: #5e5e5e;
  }
}
