.containerTitle {
  background-color: #142054;
  padding: 60px 100px;
  justify-content: center;
}
.texth4 {
  color: white;
  font-size: 35px;
  font-weight: bold;
  margin: 0px;
}
.containerSlider {
  background-color: #f2f2f2;
  padding: 100px;
}
.slider {
  text-align-last: center;
}
.personImg {
  display: flex;
  margin: auto;
  height: 100px;
  width: 100px;
  border-radius: 80px;
  border: 4px solid #fc505e;
  margin-bottom: 20px;
  position: relative;
}
.name {
  color: #fc505e;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 30px;
}
.p {
  margin-bottom: 40px;
}
.arrowRight {
  transform: rotateZ(-90deg);
}
.arrowLeft {
  transform: rotateZ(90deg);
}

@media screen and (max-width: 500px) {
  .containerSlider {
    padding: 40px;
  }
  .p {
    font-size: 11px;
  }
  .arrowLeft {
    display: none;
  }
  .arrowRight {
    display: none;
  }
}
