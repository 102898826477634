.container {
  height: 280px;
  width: 100%;
  position: relative;
}

.titleWeAreTotallia {
  color: #efefef;
  font-weight: 400;
  font-size: 1.3rem;
  margin-left: 5%;
  text-shadow: 2px 2px 4px #000000;
  margin-bottom: 0;
  margin-top: 1.18rem;
}
.pWeAreTotallia{
  color: #efefef;
  margin-left: 5%;
  font-size: 1.25rem;
  text-shadow: 2px 2px 4px #222222;
  margin-bottom: 10px;
}

.divisorProject {
  display: flex;
  justify-content: center;
  border-top: 0.24rem solid #142054;
  border-bottom: 0.24rem solid #142054;
  padding: 0.3rem 0px;
}

.titleProject{
  font-weight: 700;
  color: #142054;
  margin: 0;
}

.headerText{
  font-size: 12px;
  text-overflow: ellipsis !important;
  overflow: hidden !important;
  white-space: nowrap !important;
}
.headerText:hover{
  text-overflow: clip !important;
  overflow: visible !important;
  white-space: normal !important;
}

.projects {
  background-color: rgb(248, 248, 229);
}

.borderTopRadius{
  border-top-left-radius: 1.8rem;
  border-top-right-radius: 1.8rem;
}
.borderBottomRadius{
  border-bottom-left-radius: 1.8rem;
  border-bottom-right-radius: 1.8rem;
}
.project{
  display: flex;
  align-items: center;
  height: 7.6rem;
  color: #706f6f;
  font-weight: 700;
}
.projectImage{
  height: 4.3rem !important;
}

@supports (object-fit: cover) {
  .imgEdificio {
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center center;
  }
}
.containerText {
  position: absolute;
  background-color: rgba(53, 53, 53, 0.397);
  padding: 2%;
  width: 100%;
  height: 100%;
  top: 0;
}
.arrow {
  height: 50px;
  position: absolute;
  top: 480px;
  right: 150px;
}
.title {
  color: white;
  font-weight: 200;
  font-size: 50px;
  margin-left: 5%;
}
.p {
  color: white;
  margin-left: 5%;
}
.rowMission {
  padding: 10px 15px;
  background-color: #142054;
  justify-content: center;
  text-transform: uppercase;
}
.titleMission {
  color: white;
  margin: 0px;
  font-size: 25px;
  font-weight: 700;
}
.colImgMission {
  height: 970px;
  width: 100%;
}
.colIconsMission {
  padding: 100px 50px 60px;
}
@supports (object-fit: cover) {
  .colImgMission img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center center;
  }
}
.icons {
  height: 3.75rem;
  width: 3.75rem;
  display: flex;
}
.h4Icons {
  font-weight: 700;
  color: #5e5e5e;
}
.pIcons {
  font-size: 0.8rem;
  color: #5e5e5e;
  width: auto;
}
.rowsIcons {
  margin-top: 25px;
  margin-bottom: 20px;
}
.rowTeam {
  padding: 40px 30px;
  background-color: #f44f5e;
  justify-content: center;
}
.colTeam {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.textTeam {
  padding: 0px 50px;
  text-align: justify;
  color: black;
}

.titleTeam {
  color: white;
  margin: 0px;
  font-size: 40px;
  font-weight: 700;
}
.imgTeam {
  height: 150px;
  width: 150px;
  border-radius: 50%;
  margin-bottom: 30px;
  border: 4px solid #f44f5e;
}
.rowTeamMaters {
  padding: 100px;
  background-color: #f9f9f9;
}
.name {
  text-align: center;
  font-weight: 700;
  color: #5e5e5e;
}
.pTeam {
  text-align: center;
  color: #5e5e5e;
}
@media screen and (max-width: 1150px) {
  .projectImage{
    height: 3.3rem !important;
  } 
}
@media screen and (max-width: 990px) {
  .projectImage{
    height: 2rem !important;
  } 
}

@media screen and (max-width: 800px) {
  .containerText {
    padding-top: 2rem;
  }
  .title {
    top: 70px;
    right: 80px;
    font-size: 40px;
  }
  .p {
    top: 145px;
    right: 90px;
  }
  .arrow {
    top: 190px;
    right: 150px;
  }
  .rowMission {
    padding: 20px 20px;
  }
  .titleMission {
    font-size: 30px;
  }
  .colImgMission {
    height: 300px;
  }
}
@media screen and (max-width: 623px) {
  .titleWeAreTotallia {
    font-size: 1.2rem;
    margin-top: 0.4rem;
  }
  .pWeAreTotallia{
    font-size: 1.1rem;
  }
}
@media screen and (max-width: 500px) {
  .title {
    font-size: 30px;
  }
  .p {
    font-size: 12px;
  }
  .arrow {
    top: 190px;
    right: 50px;
  }
  .rowMission {
    padding: 17px 17px;
  }
  .titleMission {
    font-size: 25px;
  }
  .colImgMission {
    height: 200px;
  }
  .colIconsMission {
    padding: 100px 10px;
  }
  .icons {
    height: 60px;
    margin-left: 20px;
  }
  .pIcons {
    font-size: 8px;
  }
  .titleWeAreTotallia {
    font-size: 1.1rem;
  }
  .pWeAreTotallia{
    font-size: 1rem;
  }
}
@media screen and (max-width: 575px) {
  .projectImage{
    height: 4rem !important;
    width: 4rem !important;
  } 
}

