.container {
  margin: 15px 0 30px;
  background-color: #e6e7e9;
  border-radius: 20px;
  padding: 30px;
  width: auto;
  height: auto;
}

.btnConfirm {
  border-radius: 10px;
  margin-bottom: 20px;
  text-align: center;
}

.containerButton {
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 70px;
  margin-top: 70px;
  cursor: pointer;
}

.containerSign {
  text-align: center;
}

.containerPdfReader {
  text-align: center;
}

.imgDownload{
  display: block;
  width: 60px;
  margin-bottom: 10px;
}