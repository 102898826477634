.logo {
  height: 50px;
}
.container{
  background-color: #142054;
  color: white;
  padding: 80px 100px;
  border-bottom: 20px solid #f54251;
}
.container a {
  color: white;
  font-size: 11px;
}
.title{
  color: white;
  margin: 0px 0px 2px;
  font-weight: bold;
}
.hr{
  width: 80px;
  margin: 5px 0px;
  background-color: white;
}
.colFooter{
  display: flex;
  flex-direction: column;
}
.icons{
  margin-left: 5px;
}
.divIcons i{
  margin: 0px 8px;
  font-size: 18px;
}
.followNet{
  margin-top: 12px;
  font-size: 12px;
}
@media screen and (max-width: 991px){
  .colFooter{
    margin:10px;
  }
  .divNosotros{
    margin-left: 244px;
  }
  .divInvestment{
    margin-left: 50px;
  }
}
@media screen and (max-width: 800px) and (orientation: landscape) {
  .colFooter{
    margin: 0px;
  }
  .divInvestment{
    margin-left: 50px;
  }
  .divNosotros{
    margin-left: 245px;
  }
}
@media screen and (max-width: 747px){
  .colFooter{
    margin: 0px;
    margin-bottom: 7px;
  }
  .divInvestment{
    margin-left: 30px;
    margin-bottom: 7px;
  }
  .divNosotros{
    margin-left: 224px;
  }
}
@media screen and (max-width: 670px){
  .container{
    padding: 30px 40px;
  }
  .divNosotros{
    margin-left: 248px;
  }
  .divIcons i{
    margin-bottom: 20px;
  }
  .colLogo{
    margin-left: 25px;
  }
}
@media screen and (max-width: 578px){
  .container{
    padding: 30px 40px;
  }
  .colFooter{
    margin:10px;
  }
  .divInvestment{
    margin-left: 50px;
    margin-bottom: 0px;
  }
  .divIcons i{
    margin-bottom: 20px;
  }
  .divContacto{
    margin-left: 15px;
  }
  .colLogo{
    margin-left: 25px;
  }
}

@media screen and (max-width: 483px){
  .container{
    padding: 30px 40px;
  }
  .colLogo {
    margin: 0px auto;
  }
  .divInvestment{
    margin: 10px auto;
  }
  .divNosotros{
    margin-left: 38px;
  }
  .divIcons i{
    margin-bottom: 20px;
  }
  .divContacto{
    margin-left: 10px;
  }
}
@media screen and (max-width: 410px){
  .divInvestment{
    margin-left: 40px;   
  }
  .divContacto{
    margin-top: 10px;
    margin-left: 40px;
  }
}
@media screen and (max-width: 381px){
  .colLogo {
    margin-bottom: 10px;
  }
  .divInvestment{
    margin-left: 35px;
  }
  .divNosotros{
    margin-left: 35px;
  }
  .divContacto{
    margin-left: 35px;
  }
}

@media screen and (max-width: 344px){
  .divAprender{
    margin-left: 35px;
  }
}