.containerImg {
  height: 450px;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
}
.form {
  margin-left: 0px !important;
}
.formContact {
  background-color: #f8f9fa;
  height: 360px;
  border-radius: 2rem !important;
}

@supports (object-fit: cover) {
  .imgCover {
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center center;
  }
}
.title {
  color: #142054;
  width: 460px;
  border-radius: 2rem !important;
  padding: 2rem !important;
}

.padding_extra {
  padding-bottom: 5rem !important;
}

.containerIcons {
  padding: 19px 10px;
  justify-content: space-around;
  background-color: #142054;
}
.icons {
  width: 50px;
}
.anchor {
  margin: 0px 70px 0px 20px;
  color: #f44f5e;
  font-weight: bold;
}
.divIcons {
  border-right: 2px solid #f44f5e;
}
.colImgForm {
  height: 600px;
  width: 100%;
}
.colForm {
  align-self: center;
  padding-left: 100px;
}
@supports (object-fit: cover) {
  .colImgForm img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: 70%;
  }
}
.label {
  color: rgb(148, 148, 148) !important;
  padding: 0px !important;
  margin-bottom: 0px !important;
}
.input {
  border: 0px;
  border-bottom: 1px solid rgb(148, 148, 148);
  /* max-width: 100% !important; */
}

.inputContact {
  border: 0px;
  border-bottom: 2px solid rgb(19, 19, 19);
  max-width: 100% !important;
}

.btnContact {
  padding: 0.7rem 3.5rem;
  background-color: #fc505e;
  border: none;
  border-radius: 5px;
  color: white;
  font-size: 17px;
}
.btnContact:focus {
  background-color: #fc505e;
  color: white;
  box-shadow: none;
}
.btnContact:hover {
  transition: 0.8s all ease;
  background-color: #142054;
  border: #142054;
  color: white;
}

.btnMoreInfo {
  padding: 0.7rem 3.5rem;
  background-color: #142054;
  border: none;
  border-radius: 5px;
  color: white;
  font-size: 20px;
}
.btnMoreInfo:focus {
  background-color: #142054;
  color: white;
  box-shadow: none;
}
.btnMoreInfo:hover {
  transition: 0.8s all ease;
  background-color: #fc505e;
  border: #fc505e;
  color: white;
}

.btnWtsContact {
  padding: 1rem 2rem;
  background-color: #52c41a;
  border: none;
  border-radius: 5px;
  color: white;
  font-size: 20px;
}
.btnWtsContact:focus {
  background-color: #52c41a;
  color: white;
  box-shadow: none;
}
.btnWtsContact:hover {
  transition: 0.8s all ease;
  background-color: #46a716;
  border: #46a716;
  color: white;
}

.circleItem {
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 75px;
  height: 75px;
  background-color: #142054;
  font-size: 35px;
  font-weight: 700;
}

.receibeInfoTitle {
  text-align: center;
  color: #fc505e;
  font-weight: 900;
  font-size: 2.7rem;
}
.receibeInfoContainer {
  margin: 0px 15rem;
}

.infoToInvestContainer {
  margin: 0px 9rem;
}

.selectPhoneCode > div {
  border: none !important;
  border-bottom: 2px solid rgb(19, 19, 19) !important;
}

.selectPhoneCode > div:hover {
  border-bottom: 2px solid #1890ff !important;
}

.bottomFlag {
  width: 26%;
  transform: rotate(-9deg) translate(0, -30%);
  margin-left: -10px;
}

.modalContactSuccess > div:nth-child(2) > div {
  padding: 0px;
}

.containerContactSuccess {
  background-color: rgba(217,217,217, 0.5);
  border-radius: 2rem;
  margin: 0px 4rem;
}

.dividerTrackRecord {
  width: 6rem;
  height: 0.2rem !important;
  border-radius: 7px;
  margin: 0 auto;
  color: white;
  opacity: unset;
  margin-top: 1.2rem;
}

.btn {
  margin-top: 20px;
  background-color: #f44f5e;
  border: none;
  color: white;
  font-size: 11px;
  width: 150px;
  height: 30px;
  border-radius: 10px;
}
.colImgBottom {
  height: 300px;
  position: relative;
}
.colDataDirection {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #f5f5f5;
}
.divP {
  margin-left: 100px;
}
.divP p {
  color: #5e5e5e;
}
.divP span {
  color: #5e5e5e;
  font-weight: bold;
}
@supports (object-fit: cover) {
  .imgBottom {
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center;
  }
}
.arrow {
  position: absolute;
  top: 150px;
  right: 60px;
}
.colDataDirection {
  justify-content: center;
}
@media screen and (max-width: 1000px) {
  .title {
    color: #142054;
    width: 350px;
    border-radius: 2rem !important;
    padding: 2rem !important;
  }

  .formContact {
    height: 340px !important;
  }
  .padding_extra {
    padding-bottom: 5rem !important;
  }
  .receibeInfoContainer {
    margin: 0px 4rem;
  }
  .infoToInvestContainer {
    margin: 0px 4rem;
  }
  .containerContactSuccess {
    margin: 0px 1rem;
  }
}

@media screen and (max-width: 800px) {
  .containerIcons {
    padding: 30px 10px;
  }
  .anchor {
    margin: 0px 30px 0px 10px;
    color: #f44f5e;
    font-weight: bold;
  }
  .colForm {
    padding-left: 70px;
  }
  .arrow {
    position: absolute;
    top: 150px;
    right: 30px;
  }
  .containerContactSuccess {
    margin: 0px;
  }
}
@media screen and (max-width: 768px) {
  .containerImg {
    height: 740px;
    width: 100%;
  }
  .formContact {
    width: 515px !important;
  }
  .padding_extra {
    padding-bottom: 32px !important;
  }
  .title {
    width: 460px;
  }
  .infoToInvestContainer {
    margin: 0px 0rem;
  }
  .bottomFlagRed {
    transform: rotate(-9deg) translate(0, -30%);
  }
  .bottomFlagBlue {
    margin-left: -10px;
    transform: rotate(-9deg) translate(0, -20%);
  }
}
@media screen and (max-width: 576px) {
  .containerImg {
    height: 801px;
    width: 100%;
  }
  .title {
    width: 350px;
  }
}
@media screen and (max-width: 500px) {
  .title {
    left: 80px;
  }
  .containerIcons {
    padding: 20px 10px;
  }
  .divIcons {
    border-right: 0px;
    margin: 30px;
  }
  .margin {
    margin: 30px;
  }
  .colImgForm {
    height: 400px;
    width: 100%;
  }
  .colForm {
    padding: 50px 40px;
  }
  .colDataDirection {
    width: 100%;
    padding: 50px;
  }
  .colImgBottom {
    width: 100%;
  }
  .rowReverse {
    flex-direction: column-reverse;
  }
  .arrow {
    transform: rotateZ(-90deg);
    top: 80px;
    right: 50px;
  }
  .divP {
    margin-left: 70px;
  }
}
@media screen and (max-width: 466px) {
  .title {
    width: 290px;
  }
}
@media screen and (max-width: 403px) {
  .title {
    width: 240px;
  }
}
@media screen and (max-width: 358px) {
  .title {
    width: 210px;
  }
}
@media screen and (max-width: 327px) {
  .title {
    width: auto;
  }
}
