.containerImg {
  height: 600px;
  width: 100%;
  position: relative;
}
.containerGray {
  height: 600px;
  width: 100%;
  position: relative;
  background-color: #b3b3b3;
}
.boxInfoInvestment{ 
  position: absolute;
  width: 499px;
  background-color: white;
  border-radius: 1cm;
  padding: 1rem 1.5rem;
  left: 150px;
  top: 100px;
}
@supports (object-fit: cover) {
  .imgCover {
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center center;
  }
}
.title {
  font-size: 40px;
  color: #142054;
}
.p1 {
  /* width: 450px; */
  font-size: 18px;
}
.arrowDown {
  height: 40px;
  margin-top: 100px;
}
.containerCarousel {
  padding: 50px 80px;
  height: 350px;
}
.itemCarousel {
  height: 250px;
  width: 100px;
  padding: 50px;
}
.viewImg {
  color: #142054;
  font-weight: 700;
}
.rowBtn {
  justify-content: center;
}
@supports (object-fit: cover) {
  .itemCarousel img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center center;
  }
}
.col1 {
  height: 480px;
  background-color: #f44f5e;
  color: white;
}
.h4Indicators {
  color: white;
  font-size: 40px;
  padding: 35px;
  text-align: center;
  margin: 20px 0px;
}
.col2 {
  height: 480px;
  background-color: #f5f5f5;
  padding: 100px 60px;
  width: 100%;
  max-height: 100%;
}
.colIndicators div {
  margin-left: 40px;
}
.ptitle {
  font-size: 18px;
}
.pText {
  font-weight: 700;
  font-size: 25px;
}
.h4Proyect {
  font-weight: 700;
  color: #5e5e5e;
}
.pProyect {
  color: #5e5e5e;
  font-weight: 600;
  width: 600px;
}
.p3 {
  margin: 0px;
  color: #5e5e5e;
}
.p2Proyect {
  margin-top: 100px;
  font-size: 10px;
}
.card {
  width: 400px;
  border-radius: 20px;
  border: none;
  -webkit-box-shadow: -1px -2px 12px 6px rgba(183, 183, 183, 0.74);
  box-shadow: -1px -2px 12px 6px rgba(183, 183, 183, 0.74);
  position: relative;
}
.colCards {
  padding: 100px 30px;
}
.imgDownload {
  height: 70px;
  margin: 20px;
}
.imgIcon{
  height: 70px;
  width: 70px;
  margin: auto;
}
.divDownloads {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
.btnDownload {
  background-color: #f44f5e;
  padding: 5px;
  border-radius: 0px 0px 20px 20px;
  text-align: center;
}
.btnName {
  color: white;
  font-weight: bold;
}
.btnName:hover {
  color: #142054;
}
.titleDownload {
  color: #5e5e5e;
  font-weight: bold;
  font-size: 15px;
  margin: 30px 40px 0px;
}
.pDownload {
  color: #5e5e5e;
  font-size: 12px;
  margin: 0px 40px 20px;
}
.video {
  height: 220px;
  width: 388px;
  border-radius: 10px;
}
.btnPlay {
  position: absolute;
  top: 40%;
  left: 46%;
}
.justifyCenter {
  justify-content: center;
}
.titleDetailInvestment {
  font-size: 25px;
  font-weight: 700;
  color: #142054;
}
.collapse {
  margin-top: 40px;
  padding: 10px;
  width: 80%;
}
.panel {
  margin: 20px 0px;
  border-radius: 10px;
  -webkit-box-shadow: -1px -2px 12px 6px rgba(183, 183, 183, 0.74);
  box-shadow: -1px -2px 12px 6px rgba(183, 183, 183, 0.74);
  border-radius: 10px;
}
.titlePanel {
  padding: 10px;
  font-weight: 700;
}
.btnToInvest {
  background-color: #f44f5e;
  border: none;
  color: white;
  font-size: 20px;
  width: 180px;
  height: 40px;
  border-radius: 10px;
  margin: 50px 0px 100px 0px;
}
.btnToInvest:hover {
  background-color: #034078 !important;
  color: white;
  transform: scale(1.1);
}

.marginRowBtnDisable{
  margin-top: 8rem;
}

@media screen and (max-width: 1100px) {
  .boxInfoInvestment{ 
    position: absolute;
    background-color: white;
    border-radius: 1cm;
    padding: 0;
    width: 395px;
  }
  .title {
    font-size: 30px;
    left: 80px;
    padding: 1rem 1.5rem;
    padding-bottom: 0;;
    margin-bottom: 0;
  }
  .p1 {
    font-size: 12px;
    padding: 1rem 1.5rem;
    margin-bottom: 0;
  }
  .itemCarousel {
    padding: 30px;
  }
  .h4Indicators {
    font-size: 30px;
  }
  .pProyect {
    width: 400px;
  }
  .arrowDown {
    margin-left: 1rem;
    margin-bottom: 1rem;
  }
}
@media screen and (max-width: 800px) {
  .pContainer {
    padding: 100px 30px 100px 55px;
  }
  .containerCarousel {
    padding: 30px 60px;
    height: 300px;
  }
  .itemCarousel {
    padding: 20px;
    height: 200px;
  }
  .pProyect {
    width: 300px;
  }
  .colCards {
    padding: 50px 30px;
  }
  .collapse {
    margin-top: 30px;
    width: 85%;
  }
  .titlePanel {
    padding: 10px;
    font-weight: 700;
  }
}
@media screen and (max-width: 655px) {
  .containerImg {
    height: 450px;
    width: 100%;
  }
  .boxInfoInvestment{
    top: 50px;
    left: 130px;
  }
}
@media screen and (max-width: 630px) {
  .boxInfoInvestment{
    left: 100px;
  }
}
@media screen and (max-width: 600px) {
  .boxInfoInvestment{
    left: 90px;
  }
}
@media screen and (max-width: 556px) {
  .boxInfoInvestment{
    left: 75px;
  }
}
@media screen and (max-width: 529px) {
  .boxInfoInvestment{
    left: 55px;
  }
}
@media screen and (max-width: 500px) {
  .boxInfoInvestment{ 
    left: 33px;
  }
  .containerCarousel {
    padding: 20px 40px;
    height: 300px;
  }
  .itemCarousel {
    padding: 20px;
    height: 200px;
  }
  .arrows {
    width: 20px;
  }
  .col1 {
    width: 100%;
  }
  .h4Indicators {
    font-size: 25px;
  }
  .card {
    width: 330px;
  }
  .video {
    height: 210px;
    width: 318px;
  }
  .collapse {
    margin-top: 20px;
    width: 90%;
  }
  .titlePanel {
    font-size: 15px;
  }
  .btnToInvest {
    font-size: 13px;
    width: 150px;
    height: 30px;
  }
}
@media screen and (max-width: 478px) {
  .boxInfoInvestment{ 
    left: 15px;
  }
}
@media screen and (max-width: 444px) {
  .boxInfoInvestment{ 
    left: 10px;
    right: 10px;
    width: auto;
  }
}
@media screen and (max-width: 380px) {
  .card {
    width: 300px;
  }
  .video {
    height: 200px;
    width: 288px;
  }
  .titlePanel {
    font-size: 14px;
    padding: 5px;
  }
  .pProyect {
    width: 210px;
  }
  .col2 {
    padding: 60px 50px;
  }
}

@media screen and (max-width: 289px) {
  .containerImg {
    height: 470px;
  }
  .boxInfoInvestment{ 
    top: 25px;
  }
}
