.formBank {
  margin-top: 80px;
}
.containerTable {
  margin-top: 80px;
}
.pTypeBank {
  margin: 0px;
}
.btnAddBank {
  background-color: #db4437;
  border: none;
  color: white;
  font-size: 15px;
  width: 180px;
  height: 40px;
  border-radius: 5px;
  margin-right: 50px;
}
.btnAddBank:hover {
  background-color: white;
  color: #034078;
}
.h5Title {
  margin-top: 30px;
  color: #db4437;
}
.rowForm {
  position: relative;
}
.titleSlider {
  position: absolute;
  left: 10px;
}
.btnUpload {
  margin: 25px 0px 20px 100px;
}
