.rowDirection {
  display: flex;
  flex-direction: row;
}
.pPopover {
  margin-left: 5px;
}
.pUsers {
  margin: 0px;
}
.divFilters {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin: 20px;
}
.addUserForm {
  margin-left: 50px;
}
.btnCancel {
  background-color: #034078;
  border: none;
  color: white;
  font-size: 11px;
  width: 150px;
  height: 30px;
  border-radius: 10px;
}
.btnCancel:hover {
  background-color: rgba(232, 225, 225, 0.63);
  color: black;
}
.btnAddUser {
  background-color: #db4437;
  border: none;
  color: white;
  font-size: 12px;
  width: 150px;
  height: 30px;
  border-radius: 5px;
  margin-right: 50px;
}
.btnAddUser:hover {
  background-color: white;
  color: #034078;
}
.btnNewsletter {
  background-color: #034078;
  border: none;
  color: white;
  font-size: 12px;
  width: 120px;
  height: 30px;
  border-radius: 5px;
  margin: 0px 30px 0px 30px;
}
.btnNewsletter:focus {
  background-color: #034078;
  color: white;
}
.detailModal span {
  font-size: 14px;
  font-weight: bold;
  color: #034078;
  margin-right: 5px;
}
.detailModal p {
  margin-bottom: 20px;
  font-size: 15px;
}
.formEditUser {
  margin-top: 50px;
}
.colBtnsEditUser {
  margin-top: 20px;
}
.btnName:hover {
  background-color: transparent;
  border-bottom: 2px solid #034078;
}
.icon {
  height: 50px;
}
@media screen and (max-width: 500px){
  .btnNewsletter {
    margin: 0px;
  }
  .btnAddUser{
    margin: 0px;
  }
  .divFilters{
    position: relative;
    margin: 5px;
  }
  .btnSwitch{
    position: absolute;
    top: 35px;
  }
  .pUsers {
    margin: 30px 0px 0px;
  }
}
