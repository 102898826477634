.logoNav {
  height: 80px;
  margin: 20px 40px;
}
.sidebarAdmin {
  margin-top: 30px;
}
.content-admin {
  padding: 24px;
  min-height: 100vw;
  margin: 24px 16px;
}
.text-user {
  color: white;
  text-align: end;
  margin-right: 10px;
  font-size: 15px;
  font-weight: 700;
}
.btn-newInversion {
  background-color: #db4437;
  border: none;
  color: white;
  font-size: 15px;
  width: 140px;
  border-radius: 10px;
  float: right;
  margin-bottom: 25px;
  display: block;
}
.btn-newInversion:hover {
  background-color: #034078;
  border: #034078;
  color: white;
}
.tabs-inversiones {
  font-weight: 700;
}

.table-heading {
  font-size: 12px;
}

/* NUEVA INVERSION */
.bg-title-nuevaInversion {
  background-color: #b3b3b3;
  color: #034078;
  font-weight: 700;
  padding: 1rem;
  margin: 10px 0px;
}
.btn-Back-inver {
  background-color: #034078;
  float: right;
  border: none;
  color: white;
  font-size: 20px;
  border-radius: 250px;
  padding: 10px;
}
.btn-save {
  background-color: #034078;
  border: none;
  color: white;
  font-size: 17px;
  width: 190px;
  border-radius: 10px;
}
.btn-save:hover {
  background-color: #b3b3b3;
}
.hr-nuevaInversion {
  border: 2px solid;
  margin-bottom: 20px;
}
.formDataPublish {
  margin-top: 10px;
}
.input-width {
  max-width: 500px;
}

.input-width-sm {
  max-width: 200px;
}

.text-area-publicacion {
  max-width: 500px;
  height: 150px;
}

.div-texto-publicacion {
  background-color: #ffd5d9;
}

.div-adjuntos {
  background-color: rgb(202, 202, 202);
}

/* TAB DATOS DE PUBLICACION */
.title-dataPublish {
  margin-top: 40px;
}

.btn-closeInv-filter {
  float: right;
  margin-bottom: 15px;
  margin-right: 4px;
}

/* IMAGE PREV */
.image-prev {
  float: left;
  width: 200px;
  margin-right: 5px;
}
.image-prev-pdf {
  height: 100px;
  width: 80px;
}
/* SWITCH OCULTAR/MOSTRAR COLUMNAS */
.div-switch-toggle-columns {
  padding: 4px;
  margin-left: 15px;
  display: flex;
}
.btn-image-prev {
  float: right;
  border: none;
  background-color: rgb(202, 202, 202);
}

.div-image-prev {
  display: flex;
  width: 230px;
  margin: 30px 0px;
}

/* ATTACHMENTES */
.div-attachments {
  margin: 20px;
}
.divider-attachments {
  padding: 30px;
}
.attachment-form-item {
  margin-top: 30px;
}
.row-attachments {
  display: inline;
}

/* MEDIAS QUERIES */
@media (max-width: 768px) {
  .btn-inversiones {
    font-size: 10px;
  }
  .nav-tabs .nav-item.show .nav-link,
  .nav-tabs .nav-link {
    font-size: 10px;
  }
}

.loading {
  justify-items: center;
}

.gutter-row {
  padding: 8px 0;
}

.input-size {
  width: 170px;
}

.select-item {
  background: transparent !important;
  max-width: 350px !important;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  background-color: transparent;
}

.btn-primary {
  background-color: #db4437;
  border: none;
  color: white;
  font-size: 17px;
  width: 190px;
}
.btn-primary:focus {
  background-color: #db4437;
  color: white;
  box-shadow: none;
}
.btn-primary:hover {
  background-color: #034078;
  border: #034078;
  color: white;
}
.sub-title {
  font-weight: 700;
  padding: 1rem;
  margin: 10px 0px;
}

.toggle-title {
  margin-left: 5px;
}

.ant-input {
  background: transparent;
  max-width: 350px;
}
.ant-input::placeholder {
  color: gray;
  font-size: 12px;
}

.center-container {
  text-align: center;
}

.center-container-perfil {
  margin-bottom: 40px;
  text-align: center;
}

.center-div {
  align-items: flex-end;
  display: flex;
}

.ant-input-affix-wrapper {
  background: transparent;
  width: 350px;
}

.prefix-width-detail {
  width: 100px;
}

.ant-input-number {
  background: transparent;
  width: 100%;
}

.ant-input-number-input {
  background: transparent;
}

.logout-ico {
  font-size: 25px;
  color: aliceblue;
}

.search-border-color input {
  border-color: #034078;
}

.search-border-color input::placeholder {
  color: black;
  opacity: 0.8;
  font-size: 14px;
}

.datePicker-border-color input::placeholder {
  color: black;
  opacity: 0.8;
}
