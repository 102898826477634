.btnAddInvestment {
  background-color: #db4437;
  border: none;
  color: white;
  font-size: 15px;
  width: 160px;
  height: 40px;
  border-radius: 5px;
  margin-right: 50px;
}
.btnAddInvestment:hover {
  background-color: white;
  color: #034078;
}

.btnEditeInvestment {
  background-color: #db4437;
  border: none;
  color: white;
  font-size: 15px;
  width: 180px;
  height: 40px;
  border-radius: 5px;
  margin-right: 50px;
}
.btnEditeInvestment:hover {
  background-color: white;
  color: #034078;
}

.btnCreateInvestment {
  background-color: #db4437;
  border: none;
  color: white;
  font-size: 15px;
  width: 180px;
  height: 40px;
  border-radius: 5px;
  margin-right: 50px;
}
.btnCreateInvestment:hover {
  background-color: white;
  color: #034078;
}

.btnJustifyCenter{
  margin-left: 64px !important;
}

.btnAddDistribution {
  background-color: #db4437;
  border: none;
  color: white;
  font-size: 15px;
  width: 12rem;
  height: 40px;
  border-radius: 5px;
  margin-right: 50px;
}
.btnAddDistribution:hover {
  background-color: white;
  color: #034078;
}