.divDownload {
  display: flex;
  flex-direction: column;
}
.aDownload {
  text-align: center;
  color: #5e5e5e;
  font-weight: bold;
}
@supports (object-fit: cover) {
  .galleryImg img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: 70% 50%;
  }
}
.miniImg {
  height: 50px;
  width: 50px;
}
.colJustifyCenter {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.rowTitle {
  padding: 20px 0px;
  margin-top: 30px;
  background-color: #f44f5e;
}
.title {
  color: white;
  font-weight: 700;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 0;
}
.colJustifyCenter p {
  margin: auto;
  max-width: 300px;
  font-size: 12px;
  margin-top: 15px;
}
.colJustifyCenter span {
  font-weight: 700;
}
.rowIcons {
  padding: 50px 0px;
}

@media screen and (max-width: 800px) {
  .colJustifyCenter p {
    margin-bottom: 20px;
  }
}
