.buttonNavbar {
  width: 200px;
}

.btnNavbar{
  background-color: white;
  color:#034078;
  border: 0.5px solid #034078;
  border-radius: 11px;
  font-size: 15px;
  margin-right: 15px;
  box-shadow: 3px 2px 4px rgba(3, 64, 120, 0.2);
}

.dropdownItem{
  margin-left: 5%;
}

.dropdownItem::after{
  border-bottom: 2px solid #ffff !important;
}

@media screen and (min-width: 1200px){
  .dropdownItem{
    margin-left: 30%;
  }
}

.MenuDropDown {
  background-color: #142054;
}

.menuColorItem {
  color:white;
}

.contentInvestorRoom{
  margin: 0px;
}

.b{
  background-color: #efefef !important;
}

.flickered {
  animation-name: flicker;
  animation-duration: 1s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;

  -webkit-animation-name: flicker;
  -webkit-animation-duration: 1.2s;
  -webkit-animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
}

@-moz-keyframes flicker {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes flicker {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes flicker {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
