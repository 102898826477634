.icoBox {
    font-size: 35px;
    color: azure;
  }
  .containerIco {
    background-color: #f44f5e;
    padding: 2,5px;
    border-radius: 5px;
    width: 70px;
    height: 110px;
  }

  .parraf {
    font-size: 10px;
    color: azure;
    display: inline;
  }

  .containerParraf {
    text-align: center;
  }

  .icon {
    text-align: center;
    margin-top: 20px;
    margin-bottom: 5px;
  }

  .arrow {
    justify-content:center;
    margin-top: 20px;
    margin-bottom: 5px;
  }

  .containerArrow {
    padding: 10px;
    text-align: center;
    border-radius: 10px;
  }
  .icoArrow {
    font-size: 30px;
    color: #142054;
  }

@media screen and (min-width: 600px) {
  .icoBox {
    font-size: 30px;
    color: azure;
  }
  .containerIco {
    background-color: #f44f5e;
    padding: 5px;
    border-radius: 10px;
    width: 110px;
  }

  .parraf {
    font-size: 10px;
    color: azure;
    display: inline;
  }

  .containerParraf {
    text-align: center;
  }

  .icon {
    text-align: center;
    margin-top: 20px;
    margin-bottom: 5px;
  }

  .arrow {
    justify-content:center;
    margin-top: 20px;
    margin-bottom: 5px;
  }

  .containerArrow {
    padding: 10px;
    text-align: center;
    border-radius: 10px;
  }
  .icoArrow {
    font-size: 30px;
    color: #142054;
  }
}

@media screen and (min-width: 1005px) {
  .containerIco {
    background-color: #f44f5e;
    padding: 20px;
    border-radius: 10px;
    width: 250px;
    height: 200px;
  }
  
  .parraf {
    font-size: 20px;
    color: azure;
    display: inline;
  }
  
  .containerParraf {
    text-align: center;
  }
  
  .icon {
    text-align: center;
    margin-top: 20px;
    margin-bottom: 5px;
  }
  
  .arrow {
    text-align: center;
    margin-top: 20px;
    margin-bottom: 5px;
  }
  
  .containerArrow {
    padding: 20px;
    border-radius: 10px;
  }
  
  .icoBox {
    font-size: 70px;
    color: azure;
  }
  
  .icoArrow {
    font-size: 70px;
    color: #142054;
  }
  
}
