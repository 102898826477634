.loadingSpiner {
  margin: 20px 0;
  margin-bottom: 20px;
  padding: 200px;
  text-align: center;
  border-radius: 4px;
}

.iconStyle {
  margin-top: 20px;
} 