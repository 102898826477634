.divContactanos{
  background-color: #f54251;
  padding: 20px;
  justify-content: center;
}
.title{
  color:white;
  font-size: 30px;
  font-weight: bold;
}
.rowIcons{
  background-color: #f9f9f9;
  padding: 50px;
  justify-content: center;
}
.divIcons {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 30px 100px;
}
.divIcons img{
  margin-bottom: 30px;
}
.divIcons button {
  border: 1px solid #f54251;
  border-radius: 5px;
  color: #f54251;
  font-size: 10px;
  font-weight: bold;
  line-height: 11px;
}
.divIcons button:hover{
  color: #142054;
  border-color: #142054;
}