.containerSlider {
  height: 480px;
  width: 100%;
  display: flex;
  flex-direction: column;
}
.containerSlider ul li button {
  width: 1rem !important;
  height: 1rem !important;
  border-radius: 100% !important;
}

.containerSliderimg{
  width: 100%;
  height: 480px;
}
.optimizeImg {
  object-fit: cover;
  image-rendering: optimizeQuality;
}
.container{
  position: relative;
  width: 100%;
}
.containerText{
  position: absolute;
  padding: 4%;
  width: 100%;
  height: 100%;
  top: 0%;
  background-color: rgba(61, 61, 61, 0.404);
  display: flex;
  flex-direction: column;
  align-items: center;
}
.containerTextInvestment{
  position: absolute;
  padding: 4%;
  width: 100%;
  height: 100%;
  top: 0%;
  background-color: rgba(61, 61, 61, 0.404);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.containerTextNews{
  background-color: rgba(61, 61, 61, 0.404);
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0%;
  padding: 4%;
}
.cardLastNew {
  background-color: rgba(61, 61, 61, 0.404);
  padding: 1.5rem 2.5rem;
  width: 60%;
}
.positionTitle {
  margin-top: 4rem;
  color: white;
  font-weight: 1000;
  font-size: 50px;
  text-shadow: 2px 4px 4px #3d3d3d;
}
.positionTitleInvestment {
  color: white;
  font-weight: 1000;
  font-size: clamp(10px, 4.5vw, 4.5em);
  text-shadow: 2px 4px 4px #3d3d3d;
}
.positionTitleNews {
  color: white;
  font-weight: 1000;
  font-size: 1.8rem;
  margin-bottom: 0.9px;
  font-size: clamp(10px, 2.2vw, 2.2em);
}
.positionText {
  color: rgb(241, 241, 241);
  font-weight: 600;
  font-size: 27px;
  text-shadow: 2px 4px 4px #3d3d3d;
}

.positionTextInvestment {
  display: flex;
  justify-content: center;
  padding: 0 22em;
}
.positionTextInvestment p {
  text-align: center;
  color: rgb(241, 241, 241);
  font-weight: 600;
  font-size: clamp(10px, 2vw, 2em);
  text-shadow: 2px 4px 4px #3d3d3d;
}
.positionTextNews {
  color: rgb(241, 241, 241);
  font-weight: 600;
  font-size: clamp(10px, 1.9vw, 1.9em);
  overflow: hidden
}
.btn{
  background-color: #fc505e; 
  border: none;
  color: white;
  font-size: 20px;
  width: 280px;
  height: 40px;
  border-radius:10px;
  font-weight: 400;
}
.btn:hover{
  background-color: #034078 !important;
  color: white;
  transform: scale(1.1)
}
.btn:focus{
  background-color: #fc505e;
  color: white;
}
.titleLastNews {
  text-align: center;
  color: #142054;
  font-weight: 700;
  padding-top: 2.5rem;
  padding-bottom: 1.5rem;
}

@media screen and (max-width: 1200px) {
  .positionTextInvestment {
    padding: 0 17em;
  }
}

@media screen and (max-width: 885px) {
  .positionTitle {
    margin-top: 2.8rem;
    font-size: 30px;
  }
  .positionText {
    font-size: 17px;
  }
  .btn{
    margin-top: 10px;
    font-size: 13px;
    width: 190px;
    height: 27px;
  }
  .containerSlider {
    height: 300px;
  }
  .containerSliderimg{
    height: 300px;
  }
  .positionTextInvestment {
    display: flex;
    justify-content: center;
    padding: 0 10em;
  }
}

@media screen and (max-width: 539px) {
  .positionTitle {
    margin-top: 1.7rem;
    font-size: 20px;
  }
  .positionText {
    font-size: 12px;
  }
  .btn{
    font-size: 10px;
    width: 140px;
    height: 20px;
  }
  .containerSlider {
    height: 200px;
  }
  .containerSliderimg{
    height: 200px;
  }
  .positionTextInvestment {
    padding: 0 5em;
  }
  .cardLastNew {
    width: 100%;
  }
}

@media screen and (max-width: 365px) {
  .positionTitle {
    margin-top: 1rem;
    font-size: 17px;
  }
  .positionText {
    font-size: 12px;
  }
  .btn{
    font-size: 12px;
    width: 170px;
    height: 30px;
  }
  .containerSlider {
    height: 220px;
  }
  .containerSliderimg{
    height: 220px;
  }
}