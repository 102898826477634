.WhatsappLink{
    position: fixed;
    bottom: 10%;
    right: 2%;
}

.WhatsappPng{
    width: 100px;
    height: 100px;
    background-size: contain;
    background-position: center;
    cursor: pointer;
}