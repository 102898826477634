.container {
  padding: 100px 0px 50px 0px;
}
.title {
  color: #f44f5e;
  font-size: 40px;
  font-weight: bold;
  text-align: center;
}
.rowCards {
  padding: 0px 50px 50px 50px;
  justify-content: space-around;
  
}
.rowCentered {
  justify-content: center;
  margin-top: 50px;
  padding: 50px;
}

.rowCenteredButton {
  justify-content: center;
  padding: 50px;
}
.verTodas{
  font-size: 15px;
  color: #f44f5e;
}