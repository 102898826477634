.btnConfirm {
  border-radius: 10px;
  margin-bottom: 20px;
}

.switchColorSelect {
  background-color: #1db954;
}

.switchColorNotSelect {
  background-color: #b4b5b8;
}

.columnsForm {
  margin: 20px;
  width: 300px;
}

.inputNumber {
  width: 280px;
}

.wrapper {
  background-color: #e6e7e9;
  margin-bottom: 60px;
  padding: 20px;
  border-radius: 20px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(18rem, 2fr));
}

.inputWarning {
  border-color: red;
  width: 278px;
  border-width: 2px;
}

.inputOk {
  width: 278px;
}

.warningMessage {
  color: red;
}

.formItem {
  margin-bottom: 1px;
  margin-top: 20px;
}
