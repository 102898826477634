#thankYouPage {
  height: 100%;
}
.thankyouPage-nav {
  padding: 15px;
  background-color: rgba(232, 225, 225, 0.63);
}
.imgEdificio {
  height: 105%;
  width: 100%;
}
.h2-thankYouPage {
  font-size: 80px;
  margin-top: 100px;
  margin-left: 100px;
  color: #fc505e;
  font-weight: bold;
}
.h4-thankYouPage {
  margin-left: 100px;
}
.col-p-thankYouPage {
  margin-top: 50px;
  margin-left: 100px;
}
.p-thankYouPage{
  margin: 0px;
}
.btn-home{
  margin-top: 30px;
  background-color: #fc505e;; 
  border: none;
  color: white;
  font-size: 20px;
  width: 200px;
  height: 50px;
  border-radius:10px;
}
.btn-home:hover {
  background-color: #034078;
  color: white;
}

@media screen and (max-width: 800px) {
  .h2-thankYouPage {
    font-size: 50px;
  }
  .h4-thankYouPage {
    font-size: 15px;
    margin-left: 100px;
  }
  .col-p-thankYouPage {
    margin-top: 30px;
    margin-left: 100px;
  }
}

@media screen and (max-width: 500px) {
  .h2-thankYouPage {
    margin-left: 40px;
    font-size: 30px;
  }
  .h4-thankYouPage {
    font-size: 15px;
    margin-left: 40px;
  }
  .col-p-thankYouPage {
    margin-top: 30px;
    margin-left: 40px;
  }
  .btn-home {
    width: 180px;
    height: 40px;
  }
  .bg-image-mobile {
    background-image: linear-gradient(rgba(255, 255, 255, 0.8), rgba(255,255,255,0.8)), url(../img/thankYouPage.jpg);
    background-size: cover;
    padding-bottom: 90px;
  }
}
