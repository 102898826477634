.optionsBank > span {
  height: 50px !important;
  margin-top: 90px;
  display: flex;
}

.optionsBank::before {
  background-color: transparent !important;
  color: black;
}
.optionsBank:hover {
  color: black;
}

.optionsBank {
  background-color: #f0f2f5;
}

.textsOptions{
  position: absolute;
  top: 10px;
  left: 0;
  right: 0;
  margin-left: auto; 
  margin-right: auto; 
  width: 100%;
  font-size: 1.4rem;
}