.containerImg {
  height: 600px;
  width: 100%;
  position: relative;
}
@supports (object-fit: cover) {
  .containerImg img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center center;
  }
}
.containerText {
  position: absolute;
  padding: 2%;
  width: 100%;
  top: 100px;
  background-color: rgba(2, 2, 2, 0.26);
}
.title {
  margin-left: 3%;
  font-size: 50px;
  color: white;
}
.p1 {
  margin-left: 3%;
  font-size: 18px;
  color: white;
}
.p2 {
  margin-left: 3%;
  font-size: 18px;
  color: white;
}
.pContainer {
  padding: 150px 50px 150px 140px;
}
.bgBlue {
  background-color: #142054;
}
.bgRose {
  background-color: #ffe5e7;
}
.p3 {
  color: white;
  font-size: 18px;
}
.roseContainer {
  padding: 150px 50px 150px;
}
.h4 {
  color: #f44f5e;
  font-weight: 700;
}
.p4 {
  color: #142054;
}
.rowRose {
  margin-bottom: 60px;
}
.arrowRight {
  transform: rotateZ(-90deg);
  margin-left: 70px;
}
.rowCards {
  padding: 0px 50px 50px 50px;
  justify-content: space-around;
}
.verTodas{
  font-size: 15px;
  color: #f44f5e;
}

@media screen and (max-width: 1100px) {
  .title {
    font-size: 40px;
  }
  .p1 {
    font-size: 15px;
  }
  .p2 {
    font-size: 15px;
  }
  .p3 {
    font-size: 12px;
  }
  .roseContainer {
    padding: 100px 50px 150px;
  }
  .arrowRight {
    margin-left: 30px;
  }
  .rowCards {
    padding: 60px;
    justify-content: space-around;
  }
}
@media screen and (max-width: 800px) {
  .pContainer {
    padding: 100px 30px 100px 55px;
  }
  .roseContainer {
    padding: 90px 40px 80px;
  }
  .p4 {
    font-size: 12px;
  }
  .rowRose {
    margin-bottom: 40px;
  }
  .arrowRight {
    margin-left: 10px;
  }
  .rowCards {
    padding: 40px;
  }
}
@media screen and (max-width: 500px) {
  .title {
    top: 100px;
    left: 35px;
    font-size: 30px;
  }
  .p1 {
    top: 170px;
    left: 40px;
  }
  .p2 {
    top: 235px;
    left: 40px;
  }
  .roseContainer {
    padding: 90px 50px 80px;

  }
  .bgBlue {
    width: 100%;
  }
  .bgRose {
    width: 100%;
  }
  .rowCards {
    padding: 30px 10px;
    justify-content: center;
  }
}
