/* Estilos */
.title-modal-register {
  font-weight: 700;
  margin-top: 20px;
  margin-bottom: 20px;
  text-align: center;
}
.title-modal-login{
  font-weight: 700;
  margin-top: 60px;
  margin-bottom: 40px;
  text-align: center;
}
.link-forgot-pass{
  color: gray;
  font-size: 11px;
}
.btn-SingUp{
  color:#DB4437;
  font-size: 11px;
}
.div-login-left {
  background-color: rgba(232, 225, 225, 0.63);
  height:462px;
  width: 257px;
  height: 344px;  
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.div-login-right {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.form-login {
  margin-top: 90px;
}
.ant-modal-close-x {
  font-size: 15px;
}
.div-radio-btn {
  display: flex;
}
.img-register{
  width: 100%;
  height: 100%;
}
.radio-btn{
  font-size: 10px;
  border-radius: 10px;
}
.btn-form {
  font-size: 15px;
  background-color: white;
  border-radius:10px;
  border: none;
  color: black;
}
.btn-form:hover {
  font-size: 15px;
  background-color: #C4C4C4;
  border: none;
  color: black;
}
.inputs-logRegister {
  background-color: transparent;
  border: none;
  border-bottom: 1px solid gray;
}
.inputs-logRegister::placeholder{
  color: gray;
  font-size: 13px;
}
.form-register-position{
  margin-left: 20px;
}
.cancel-link-register{
  background-color: #034078;
  color: white;
}
.cancel-link-register:hover{
  background-color: #034078;
  color: white;
}
.checkbox{
  font-size: 13px !important;
  color: gray !important;
}
.checkbox-reg{
  font-size: 10px !important;
  color: gray !important;
}
.inputs-register:focus{
  background-color: #034078;
  color: white;
}
.btn-facebook{
  background-color: #3b5998;
}
.btn-google{
  background-color: white;
}
.position-btn-face-google{
  border: none;
  height: 100px;
  width: 50px;
  margin-left: 25px; 
}
.cancelBtn-formLogin{
  background-color: rgba(232, 225, 225, 0.63); 
  border: none;
  color: gray;
  font-size: 11px;
  width: 150px;
  height: 30px;
  border-radius:10px;
}
.cancelBtn-formLogin:hover{
  background-color: #034078;
}
.btn-register{
  margin-left: 100px;
  background-color: #DB4437; 
  border: none;
  color: white;
  font-size: 11px;
  width: 150px;
  height: 30px;
  border-radius:10px;
}
.btn-register:hover{
  background-color: #034078;
}
.img-login{
  background-image: url(../img/INGRESO.jpg);
  background-position: center center;
  background-size: cover;
  width: 100%;
  height: 100%;
}
.div-btn-faceGoggle{
  display: flex;
  flex-direction: column;
  justify-content:center;
}
.ant-checkbox-inner{
  border-radius: 10px;
}
#formLogin_password {
  background: transparent;
}
#formLogin_password::placeholder{
  color: gray;
  font-size: 13px;
}
#formRegister_password {
  background: transparent;
}
#formRegister_password::placeholder{
  color: gray;
  font-size: 13px;
}
.iconGoogle{
  height:30%;
  margin: -4px;
}
/* Medias Queries */
@media screen and (max-width: 768px) {
  .display-none-mobile {
    display: none;
  }
  .title-modal-register .title-modal-login{
    text-align: left;
  }
  .btn-register{
    width: 110px;
    height: 30px;
  }
  .cancelBtn-formLogin{
    width: 120px;
    height: 30px;
    border-radius:10px;
  }
  .margin-btn{
    margin-top: 60px;
  }
  .position-btn-face-google {
    height: 80px;
    width: 40px;
    margin-left: 22px;
  }
  .div-btn-faceGoggle{
    justify-content:start;
    margin-top: 150px;
  }
  .inputs-logRegister{
    width: 95%;
  }
  .img-login {
    opacity: 0.5;
    max-width: 45vw;
  }
  #formLogin_password::placeholder {
    color: gray;
  }
  #formRegister_password::placeholder {
    color: gray;
  }
  .inputs-logRegister::placeholder{
    color: gray;
  }
  .link-forgot-pass{
    color: black;
  }
  .btn-SingUp{
    font-weight: bold;
  }
  .inputs-logRegister{
    width: 80% !important;
  }
  .ant-input-affix-wrapper{
    width: 80% !important;
  }
}
@media screen and (max-width: 500px) {
  .form-register-position {
    left: 20px;
  }
  .inputs-logRegister{
    width: 80% !important;
  }
  .ant-input-affix-wrapper{
    width: 80% !important;
  }
  .position-btn-face-google {
    height: 80px;
    width: 40px;
    margin-left: 18px;
  }
  .div-btn-faceGoggle{
    justify-content:start;
    margin-top: 120px;
  }
  .img-login-mobile {
    opacity: 0.3;
    max-width: 96vw;
  }
}
@media screen and (min-width: 800px) {
  .display-none-desktop {
    display: none;
  }
}