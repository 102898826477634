body {
  margin: 0;
  font-family: 'Nunito Sans', sans-serif;
  font-weight: 400;
}

.resetPass {
  margin: auto;
  margin-top: 200px;
  max-width: 600px;
}

/* Navbar */
.bg-navbar {
  background-color: #ffffff;
  color: #034078;
}
.btn-navbar {
  background-color: white;
  color: #034078;
  border: 0.5px solid #034078;
  border-radius: 11px;
  font-size: 15px;
  margin-right: 15px;
  box-shadow: 3px 2px 4px rgba(3, 64, 120, 0.2);
}
.logoTotalliaNavbar {
  width: 100px;
  margin: 20px 60px;
}
.btn-navbar:hover {
  background-color: #034078;
  color: #ffffff;
}
.menu-items-nav {
  margin: 15px 0px 0px 60px;
  border: none;
}
.items-navbar {
  font-weight: 700;
  font-size: 12px;
  color: #0f5291 !important;
}
.btn-nav-mobile {
  padding: 0;
}
.ant-menu-item-selected {
  color: #fc505e !important;
}
.items-navbar:hover {
  color: #fc505e !important;
}
.ant-menu-item-active::after {
  border-bottom: 2px solid #fc505e !important;
}
.btn-bars-mobile {
  font-size: 30px;
  color: #0f5291;
}
.title-nav-mobile {
  margin-left: 30px;
  margin-top: 10px;
  font-size: 25px;
  color: #0f5291;
}
#drawerNav
  > .ant-drawer-content-wrapper
  > .ant-drawer-content
  > .ant-drawer-wrapper-body
  > .ant-drawer-header
  > .ant-drawer-title {
  font-size: 20px;
  font-weight: 700;
  color: #0f5291;
}
.ant-carousel .slick-dots li.slick-active button {
  background-color: #fc505e;
}
.ant-carousel .slick-dots li button {
  background-color: #fc505e;
}
.carousel-home .slick-dots li.slick-active {
  width: auto;
}

/* MEDIAS QUERIES */
@media screen and (max-width: 800.5px) {
  .display-none-mobile {
    display: none;
  }
}
@media screen and (min-width: 800px) {
  .display-none-desktop {
    display: none;
  }
}

@media screen and (min-width: 1030px) {
  .logoTotalliaNavbar {
    width: 170px;
    margin: 20px 60px;
  }
}

.react-pdf__Page__canvas {
  width: 340px !important;
  height: 450px !important;
}

.react-pdf__Page__textContent {
  width: 340px !important;
  height: 450px !important;
}

@media screen and (min-width: 600px) {
  .react-pdf__Page__canvas {
    width: 350px !important;
    height: 460px !important;
  }
}

@media screen and (min-width: 1000px) {
  .react-pdf__Page__canvas {
    width: 450px !important;
    height: 600px !important;
  }
}

@media screen and (min-width: 1200px) {
  .react-pdf__Page__canvas {
    width: 550px !important;
    height: 700px !important;
  }
}

@media screen and (min-width: 1400px) {
  .react-pdf__Page__canvas {
    width: 650px !important;
    height: 800px !important;
  }
}
.ant-input.readOnly {
  pointer-events: none;
}

.ant-dropdown-menu-item:hover {
  color: black !important;
}

.ant-dropdown-placement-bottomCenter > .ant-dropdown-arrow,
.ant-dropdown-placement-bottomLeft > .ant-dropdown-arrow,
.ant-dropdown-placement-bottomRight > .ant-dropdown-arrow {
  border-top-color: #142054;
  border-left-color: #142054;
}
