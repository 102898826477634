.img {
  height: 180px;
  width: 100%;
}
.card {
  border-radius: 20px;
  margin-top: 30px;
  box-shadow: 10px 13px 9px 0px rgba(209, 209, 209, 0.78);
  -webkit-box-shadow: 10px 13px 9px 0px rgba(209, 209, 209, 0.78);
  -moz-box-shadow: 10px 13px 9px 0px rgba(209, 209, 209, 0.78);
}
.card img {
  border-radius: 20px 20px 0px 0px;
  position: relative;
}
.title {
  text-align: center;
  font-size: 22px;
  font-weight: bold;
  color: rgb(78, 78, 78);
}
.sponsor {
  text-align: center;
  font-size: 11px;
}
.span {
  font-weight: bold;
}
.open {
  position: absolute;
  color: white;
  font-size: 18px;
  font-weight: bold;
  border-radius: 0px 20px 0px 0px;
  top: 0px;
  right: 0px;
  border-right: 65px solid #142054;
  border-top: 55px solid #142054;
  border-left: 76px solid transparent;
  border-bottom: 40px solid transparent;
}
.pOpen {
  position: absolute;
  top: -40px;
  right: -55px;
}
.close{
  position: absolute;
  color: white;
  font-size: 18px;
  font-weight: bold;
  border-radius: 0px 20px 0px 0px;
  top: 0px;
  right: 0px;
  border-right: 65px solid #979797;
  border-top: 55px solid #979797;
  border-left: 76px solid transparent;
  border-bottom: 40px solid transparent;
}
.pTitle {
  margin-bottom: 5px;
  font-size: 10px;
}
.pText {
  font-weight: bold;
}
.viewMore {
  color: #f44f5e;
  font-weight: bold;
  margin-left: 80px;
  border: none;
}
@media screen and (max-width: 1100px) {
  .card {
    margin-right: 10px;
  }
}
@media screen and (max-width: 800px) {
  .card {
    margin-right: 0px;
  }
}
