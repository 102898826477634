.rowCardsVideo {
  margin-top: 50px;
}
.divCard {
  width: 400px;
  margin-top: 50px;
}
.card {
  border-radius: 10px 10px 0px 0px;
}
.iframe {
  border-radius: 10px 10px 0px 0px;
  height: 200px;
}
.h6NameVideo {
  font-weight: bold;
  margin-right: 35px;
}
.aVideoYoutube {
  color: #f44f5e;
  font-size: 12px;
}

@media screen and (max-width: 500px) {
  .divCard {
    width: 350px;
    margin: auto;
  }
  .aVideoYoutube {
    font-size: 10px;
  }
  .divCard {
    margin-top: 40px;
  }
}

@media screen and (max-width: 400px) {
  .divCard {
    width: 300px;
  }
}
