.container{
  padding: 70px 10px 70px 30px;
  background-color: #142054;
}
.texth4 {
  font-size: 20px;
  font-weight: bold;
  color: #fc505e; 
  text-align: center;
}
.texth6{
  font-size: 18px;
  color: white;
  text-align: center;
}

@media screen and (max-width: 800px) {
  .container{
    padding: 50px;
  }
  .texth4 {
    font-size: 16px;
  }
  .texth6{
    font-size: 12px;
  }
  .proyectos {
    margin-top: 30px;
  }
  .inversores {
    margin-top: 30px;
  }
}
@media screen and (max-width: 800px) and (orientation: landscape) {
  .container{
    padding: 50px 30px;
    justify-content: space-between;
  }
  .proyectos {
    margin-top: 0px;
  }
  .inversores {
    margin-top: 0px;
  }
}
@media screen and (max-width: 700px) and (orientation: landscape) {
.container{
  justify-content: space-around;
}
}
@media screen and (max-width: 669px)  {
  .proyectos {
    margin-top: 0px;
  }
  .inversores {
    margin-top: 0px;
  }
}
@media screen and (max-width: 575px) {
  .container{
    padding: 30px;
    justify-content: center;
  }
  .texth4 {
    font-size: 12px;
  }
  .texth6{
    font-size: 8px;
  }
  .suscriptores {
    margin-left: 15px;
  }
  .proyectos {
    margin-left: 17px;
  }
  .inversores {
    margin-left: 15px;
  }
}

@media screen and (max-width: 473px) {
  .container{
    padding: 30px;
    justify-content: space-around;
  }
  .proyectos {
    margin-left: 0px;
    padding-right: 0px;
  }
  .inversores {
    margin-left: 0px;
    padding-right: 0px;
  }
}