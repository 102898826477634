.container {
  height: 280px;
  width: 100%;
  position: relative;
}
@supports (object-fit: cover) {
  .imgEdificio {
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center center;
  }
}
.title {
  position: absolute;
  top: 100px;
  right: 110px;
  color: #164178;
  font-weight: 200;
  font-size: 50px;
  text-shadow: 2px 2px 2px #ffffff;
}
.tabPaneTitle {
  margin: 0px 20px;
  color: #5e5e5e;
}
.tabs {
  margin: 70px 50px;
}
.imgCardContainer {
  height: 270px;
  width: 100%;
}
@supports (object-fit: cover) {
  .imgCard {
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center center;
  }
}
.pClasification {
  margin: 20px 50px;
  color: #f44f5e;
}
.titleCard {
  margin: 10px 50px;
  font-weight: bold;
}
.pWriter {
  font-weight: bold;
  color: #5e5e5e;
  margin-left: 50px;
}
.pDate {
  margin-left: 15px;
  color: #5e5e5e;
}
.btnReadMore {
  font-size: 17px;
  color: #f44f5e;
  margin-right: 20px;
}
.card {
  -webkit-box-shadow: 4px 1px 7px 6px rgba(132, 132, 132, 0.48);
  box-shadow: 4px 1px 7px 6px rgba(132, 132, 132, 0.48);
  margin-top: 25px;
  margin-bottom: 25px;
  width: 100%;
  border: none;
}
.rowCard {
  height: 270px;
}
.divRowCards {
  width: 900px;
}
.readMore {
  position: absolute;
  bottom: 0;
  right: 0;
  padding-bottom: 20px;
}
.aViewMore {
  color: #f44f5e;
  margin-top: 50px;
}
.arrow {
  transform: rotateZ(270deg);
  margin-left: 15px;
  height: 30px;
}
.icon {
  height: 30px;
  margin: 10px;
}
.aIcon {
  border-radius: 15px;
  border: 2px solid #f44f5e;
  margin: 0px 10px;
}
.containerImg {
  width: 80%;
}
.bodyParagraph {
  margin: 65px;
}
.cardDetailArticle {
  -webkit-box-shadow: 4px 1px 7px 6px rgba(132, 132, 132, 0.48);
  box-shadow: 4px 1px 7px 6px rgba(132, 132, 132, 0.48);
  margin: 50px 0px;
}
.rowAllArticles {
  margin-bottom: 50px;
}
@supports (object-fit: cover) {
  .imgBodyArticle {
    height: 80%;
    width: 80%;
    object-fit: cover;
    object-position: center center;
    margin: 0px 150px;
  }
}
@media screen and (min-width: 1100px) {
  .divRowCards {
    width: 1200px;
    margin: auto;
  }
}
@media screen and (max-width: 800px) {
  .readMore {
    padding-bottom: 0;
  }
  .container {
    height: 250px;
  }
  .title {
    top: 70px;
    right: 80px;
    font-size: 40px;
  }
  .bodyParagraph {
    margin: 50px;
  }
  .cardDetailArticle {
    -webkit-box-shadow: 4px 1px 7px 6px rgba(132, 132, 132, 0.48);
    box-shadow: 4px 1px 7px 6px rgba(132, 132, 132, 0.48);
    margin: 50px 20px;
  }
  .divRowCards {
    width: 650px;
  }
}
@media screen and (max-width: 770px) {
  .rowCard {
    height: auto !important;
  }
}
@media screen and (max-width: 500px) {
  .title {
    top: 40px;
    right: 50px;
    font-size: 30px;
  }
  .pClasification {
    margin: 0px 10px;
    color: #f44f5e;
  }
  .titleCard {
    margin: 10px 10px;
    font-weight: bold;
  }
  .pWriter {
    font-weight: bold;
    color: #5e5e5e;
    margin-left: 10px;
  }
  .pDate {
    margin-left: 15px;
    color: #5e5e5e;
  }
  .btnReadMore {
    font-size: 17px;
    color: #f44f5e;
    margin-right: 10px;
  }
  .searchInput {
    margin-top: 30px;
  }
  .tabs {
    margin: 30px;
  }
  .icon {
    height: 20px;
    margin: 6px;
  }
  .aIcon {
    border-radius: 15px;
    border: 2px solid #f44f5e;
    margin: 10px 10px 0px;
  }
  .aViewMore {
    margin-top: 20px;
  }
  .imgBodyArticle {
    margin: 0px 50px;
  }
  .containerImg {
    height: 300px;
  }
  .colCardData {
    width: 100%;
    margin-left: 20px;
  }
  .divRowCards {
    width: 300px;
    margin: auto;
  }
}
